import React from "react";
import "../styles/NotFound.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="page404">
      <FontAwesomeIcon icon={faXmark} size="3x" />
      <h2>{t("notFound")}</h2>
      <p>
      <a href="/">{t("back")}</a>.
      </p>
    </div>
  );
};

export default NotFound;
